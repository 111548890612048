<template>
    <div class="wrap-container">
        <div
            class="pad-tb-2 wrap-banner"
            :style="{'background':`url(${require('@/assets/deyangImg/zcjsqBanber.png')}) no-repeat 100% / cover`}"
        >
            <!-- url("~@/assets/images/底图.png") no-repeat 100% / cover -->
            <div class="def-container banner-inner">
                <div class="banner-left">
                    <div
                        v-if="enterpriseLogo"
                        class="pad-2 enterprise-logo_outer"
                    >
                        <img
                            class="enterprise-logo"
                            :src="enterpriseLogo"
                        />
                    </div>
                    <div>
                        <h2 class="enterprise">{{userInfo.enterpriseInfo.enterprise}}</h2>
                        <p class="enterprise-capital">
                            历史扶持
                            <span class="pad-rl-1 capital-number">
                                <span style="font-size:18px;font-weight:bold;">{{projectTotal.factnbje}}</span>
                                万元
                            </span>
                        </p>
                    </div>
                </div>
                <div class="banner-right">
                    <div
                        v-for="(item,index) in cpu_totalList"
                        :key="index"
                        class="pad-rl-3 project_total-item"
                    >
                        <label>{{item.label}}</label>
                        <h2>{{item.value}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- 切换 -->
        <div class="pad-tb-3 mid-content">
            <div class="def-container workplace-container">
                <nav class="pad-tb-1 bord-r-1 workplace-header-nav">
                    <el-menu
                        :default-active="activeIndex"
                        mode="vertical"
                        @select="handleSelect"
                        active-text-color="#ffffff"
                        :router="true"
                    >
                        <el-menu-item
                            v-for="el in cpu_menuList"
                            :key="el.value"
                            :index="el.value"
                        >
                            <div
                                class="menuItem-label"
                                slot="title"
                            >
                                <p>
                                    <i :class="el.icon"></i>
                                    <span>{{el.name}}</span>
                                </p>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </el-menu-item>
                    </el-menu>
                </nav>
                <!-- 主要输出结构 -->
                <div class="main-container">
                    <router-view />
                </div>
            </div>
        </div>

        <!-- 尾巴 -->
        <!-- <div>
            <fixed-footer></fixed-footer>
        </div> -->
    </div>
</template>

<script>
// import { FixedFooter } from "@/layout/components/index";
import { mapGetters } from "vuex";
import variableStyle from '@/styles/variable.scss'


export default {
    name: "Home",
    components: {
        // FixedFooter,
    },
    data() {
        return {
            enterpriseLogo: '', // 企业logo(base64)
            isAccountant: false,//是否为会计师
            loading: true,
            // 统计
            projectTotal: {
                dtjnum: 0, // 暂存件
                shnum: 0, // 审核中
                thnum: 0, // 已退回
                bjnum: 0, // 已办结
                factnbje: 0, // 历史扶持总资金
                byslnum:0,
            },
            /* 用户状态，用于分辨是公司账号 还是 个人账号 */
            userType: 0, // 0 是公司 1 是个人 string
            // header-right
            items: [
                {
                    name: "安全退出",
                    path: "/home",
                },
            ],
            // nav
            activeIndex: "",
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
        }),
        /**
         * @description: banner政策申报项目统计列表
         */
        cpu_totalList() {
            return [
                { label: '暂存件', value: this.projectTotal.dtjnum },
                { label: '审核中', value: this.projectTotal.shnum },
                { label: '已退回', value: this.projectTotal.thnum },
                { label: '已办结', value: this.projectTotal.bjnum },
                { label: '不予受理', value: this.projectTotal.byslnum },
            ]
        },
        /**
         * @description 侧边导航栏列表
         */
        cpu_menuList() {
            /**
             * 20210419
             * 区分角色
             *     会计师: [全部项目]
             *     others：[我的工作台, 我的项目, 我的材料, 消息中心, 账户设置, 我的诉求, 我的关注, 计划录入]
             */
            let list = []
            if (this.userInfo.kjs == '1') {
                list = [{
                    name: '全部项目',
                    value: '/workplace/backlog',
                    icon: 'el-icon-document-copy',
                }]
            } else {
                list = [
                    // {
                    //     name: '推送政策',
                    //     value: '/workplace/policy',
                    //     icon: 'el-icon-document-copy',
                    // },
                    {
                        name: '我的项目',
                        value: '/workplace/index',
                        icon: 'el-icon-document-copy',
                    },
                    {
                        name: "我的材料",
                        value: "/workplace/file",
                        icon: "el-icon-paperclip",
                    },
                    // {
                    //     name: '我的诉求',
                    //     value: '/workplace/myAppeal',
                    //     icon: 'el-icon-document-copy',
                    // },
                    {
                        name: '账户设置',
                        value: '/workplace/setting',
                        icon: 'el-icon-setting',
                    },
                    {
                        name: "我的订阅",
                        value: "/workplace/subscribe",
                        icon: "el-icon-bell",
                    },
                    //  {
                    //     name: "我的诉求",
                    //     value: "/workplace/appeal",
                    //     icon: "el-icon-chat-line-square",
                    // },
                    // {
                    //     name: '我的证照',
                    //     value: '/workplace/myAppeal',
                    //     icon: 'el-icon-document-copy',
                    // },
                    // {
                    //     name: "我的关注",
                    //     value: "/workplace/collection",
                    //     icon: "el-icon-star-off",
                    // },
                    // {
                    //     name: "计划录入",
                    //     value: "/workplace/plan",
                    //     icon: "el-icon-paperclip",
                    // },
                ]
            }
            return list
        },
        cpu_themeColor() {
            return variableStyle['color-theme']
        },
    },
    methods: {
        // 获取登录账号详细信息
        getBaseInfo() {
            this.$httpApi
                .get(
                    "/dev-api/getInfo" + `?appid=${this.$httpApi.appid}&appkey=${this.$httpApi.appkey}`,
                    {}
                )
                .then((res) => {
                    if (res.code === 200 && res.data) {
                        // console.log("信息>>>", res);
                        let baseInfo = res.data.baseInfo;
                        if (baseInfo != null) {
                            this.$store.commit("updateAccountBaseInfo", baseInfo);
                        }
                        let userInfo = res.data.userInfo;
                        // 判断是哪种类型账号 （公司账号信息必有社会信用代码，以此为判断条件）
                        if (userInfo.shxydm) {
                            this.userType = 0;
                        } else {
                            this.userType = 1;
                        }
                        let mid = {
                            icon: require("@/assets/images/user_icon.png"),
                            userStatus: "已经绑定账号", // 用户是否绑定账号状态
                            emailStatus: "未绑定邮箱", // 邮箱状态
                            security: "1", // 安全等级 0 1 2
                        };
                        Object.assign(userInfo, mid);
                        userInfo.shxydm = baseInfo.shxydm
                        this.$store.commit("updateUserInfo", userInfo);
                    }
                })
                .catch((err) => {

                });
        },
        toPath(el) {
            console.log("转跳到对应网站", el);
            if (el.path == "/home") {
                this.$confirm("是否退出登录？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$store.dispatch("logout", {}).then(() => {
                            this.$message.success('登出成功')
                            this.$router.push('/home')
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消退出",
                        });
                    });
            } else {
                this.$router.push({ path: el.path });
            }
        },
        headerLogin() {
            console.log("header的登入");
        },
        // nav
        handleSelect(key, keyPath) {
            // console.log("aa",key);
            this.activeIndex = key;
            this.accIndex = key;
            // console.log("this.active", this.activeIndex)
        },
        /**
         * @description: 更新政策申报项目统计数目(提供给子组件用)
         * @param {Object} totalObj 统计信息
         */
        updateProjectTotal(totalObj) {
            for (const [k, v] of Object.entries(totalObj)) {
                this.projectTotal[k] = v || 0
            }
        },
        getEnterpriseLogo() {
            let url = '/dev-api/biq/download'
            let params = {
                id: this.userInfo.enterpriseInfo.logoId,
                // id: 'biqtest$$RES_CORP/51e0e9b3-86f8-4cdf-a441-4d2cd8c8c002.png',
                imgType: '1',
            }
            this.$httpApi.get(url, params).then(res => {
                // console.log('getEnterpriseLogo:', res)
                if (res.code == 200) {
                    this.enterpriseLogo = `data:image/png;base64,${res.data.img}`
                }
            }).catch(err => {
                console.log('getEnterpriseLogo error:', err)
            })
        },
        /**
         * @description: 查询政策申报项目统计数目
         */
        getProjectTotal() {
            let url = '/dev-api/busitjfx/data/find/busi_qy_tjfx'
            let params = {
                tyxydm: this.userInfo.tyshxydm,
                itemtype: 1,
            }
            this.$httpApi.post(url, params).then(res => {
                // console.log('getProjectTotal:', res)
                if (res.data && res.data[0]) {
                    for (const [k, v] of Object.entries(res.data[0])) {
                        this.projectTotal[k] = v || 0
                    }
                }
            }).catch(err => {
                console.log('getProjectTotal error:', err)
            })
        },
    },
    provide() {
        return {
            updateProjectTotal: this.updateProjectTotal
        }
    },
    created() {
        // console.log('userInfo:', this.userInfo)
        // 获取企业logo
        if (this.userInfo.enterpriseInfo.logoId) {
            this.getEnterpriseLogo()
        }
        // 统计政策申报项目的总数
        // 在 我的项目 中，也有用到统计，如果处于这个页面，直接从子页面取值
        // 如果不是，则当前自己请求查询
        if (this.$route.path !== '/workplace/index') {
            this.getProjectTotal()
        }
        this.activeIndex = this.$route.path
        // this.getBaseInfo()
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.wrap-container {
    height: 100%;
    background-color: #f8f8f8;

    .mid-content {
        min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});

        .workplace-container {
            display: -webkit-flex;
            display: flex;
        }
        .main-container {
            flex: 1;
            position: relative;
            margin-left: 20px;
        }
    }

    .footer-container {
        // background-color: #ffffff;

        ::v-deep span {
            color: #666666;
        }
    }
}
.wrap-banner {
    background-size: 100% 100%;
    .banner-inner {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .banner-left {
            display: -webkit-flex;
            display: flex;
            align-items: center;
        }
    }
}
.enterprise-logo_outer {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;

    &::before,
    &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        background-color: rgba(#ffffff, 0.1);
    }
    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#ffffff, 0.1);
        z-index: 0;
    }
    .enterprise-logo {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        z-index: 3;
    }
    &::after {
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        background-color: rgba(#ffffff, 0.2);
        z-index: 1;
    }
}
.enterprise {
    margin-bottom: 20px;
    font-family: Microsoft YaHei;
    color: #ffffff;
}
.enterprise-capital {
    color: #ffffff;

    .capital-number {
        display: inline-block;
        padding: 2px 10px;
        // margin-left: 10px;
        // background-color: $col-theme;
    }
}
.project_total-item {
    position: relative;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #ffffff;

    &:last-child {
        padding-right: 0;
    }
}
.project_total-item + .project_total-item {
    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 10px;
        bottom: 10px;
        left: 0;
        border-left: 1px solid rgba(#ffffff, 0.2);
    }
}

.workplace-header-nav {
    width: 160px;
    height: calc(100vh - #{$headerHeight} - #{$footerHeight} - 60px);
    background-color: #ffffff;

    ::v-deep .el-menu {
        border: none !important;

        .el-menu-item {
            padding-right: 10px;
            padding-left: 10px !important;
            border-color: #ffffff;

            .menuItem-label {
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;

                p {
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 5px;
                    }
                }
                .el-icon-arrow-right {
                    font-size: 14px;
                }
            }

            &:hover {
                border-left: 4px solid $color-primary1;
            }

            &.is-active {
                border-left: 4px solid $color-primary1;
                font-weight: bold;
                background-color: $color-primary1;
            }
        }
    }
}
::v-deep .el-tabs__header .el-tabs__nav-wrap .el-tabs__active-bar {
  background-color: #65a4fc !important;
}
</style>
